import StatusBadge from 'components/atoms/status-badge'
import { carChecklistItemStatusColors } from 'config/data'
import { CarChecklistItemStatusses } from 'config/enums'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'

// In pixels
const underlineDistance = 3
const underlineThickness = 2
const borderThickness = 2

const StyledStatusBadge = styled(StatusBadge)`
  position: relative;
  ${({ $addSpacingForUnderline }) =>
    $addSpacingForUnderline
      ? `
    margin-top: ${underlineDistance + underlineThickness}px;
    margin-bottom: ${underlineDistance + underlineThickness}px;
  `
      : ''}
  border: ${({ $status, theme }) =>
    `${borderThickness}px solid ${$status === CarChecklistItemStatusses.pendingStart ? theme.colors[carChecklistItemStatusColors[$status]] : 'transparent'};`}
  color: ${({ $status, theme }) =>
    $status === CarChecklistItemStatusses.pendingStart
      ? theme.colors[carChecklistItemStatusColors[$status]]
      : '#fff'};
  ${({ $status, theme }) =>
    $status === CarChecklistItemStatusses.completedAfterDelay
      ? `&:after {
      content: '';
      display: block;
      position: absolute;
      width: calc(100% + ${2 * borderThickness}px);
      height: ${underlineThickness}px;
      background-color: ${theme.colors[carChecklistItemStatusColors[CarChecklistItemStatusses.delayed]]};
      top: calc(100% + ${borderThickness + underlineDistance}px);
      left: -${borderThickness}px;
    }`
      : ''}
  cursor: pointer;
`

const ChecklistStatusBadge = ({
  amount,
  status,
  addSpacingForUnderline,
  ...restProps
}) => {
  const statusColor =
    status === CarChecklistItemStatusses.pendingStart
      ? 'transparent'
      : carChecklistItemStatusColors[status]
  const textColor =
    status === CarChecklistItemStatusses.pendingStart
      ? carChecklistItemStatusColors[status]
      : '#fff'

  return (
    <StyledStatusBadge
      statusColor={statusColor}
      textColor={textColor}
      content={
        typeof amount === 'string' || typeof amount === 'number' ? amount : '-'
      }
      $status={status}
      $addSpacingForUnderline={addSpacingForUnderline}
      {...restProps}
    />
  )
}

export const propTypes = {
  status: PropTypes.oneOf(Object.values(CarChecklistItemStatusses)).isRequired,
  amount: PropTypes.number,

  // Adds a margin-top and margin-bottom to accomodate for the underline of the completedAfterDelay
  // status, which has position: absolute and is positioned a few pixels below this element
  addSpacingForUnderline: PropTypes.bool,
}

ChecklistStatusBadge.propTypes = {
  ...propTypes,
}

export default ChecklistStatusBadge
