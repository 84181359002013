import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import styled, { ThemeContext } from 'styled-components/macro'

import { getSupportRequestDetails } from 'redux/actions/data'

import { formatDataTestE2eAttr } from 'utilities/format'
import { media } from 'utilities/styled'
import {
  formatPhoneNumber,
  getAppTheme,
  translateListItems,
} from 'utilities/utils'

import { data } from 'config'
import { VALUATION } from 'config/routes'

import Button from 'components/atoms/button'
import Link from 'components/atoms/link'
import Menu from 'components/organisms/menu'
import MobileMenu from 'components/organisms/mobile-menu'
import NavigationBar from 'components/organisms/navigation-bar'
import ActionBar from 'components/redux-containers/action-bar-container'
import LayoutOverlays from 'components/redux-containers/layout-overlays-container'
import MobileHeader from 'components/redux-containers/mobile-header-container'

import useNavigationBarNewsItem from 'hooks/use-navigation-bar-news-item'
import usePrioritizeNavigationBarNewsItem from 'hooks/use-prioritize-navigation-bar-news-item'
import useSelectedLocationId from 'hooks/use-selected-location-id'

function getSupportMenu(t, theme, auth, supportQuestionHandleClick) {
  const { themeNameCamelCase } = getAppTheme()
  const { supportMenu } = theme
  const menu = []

  if (supportMenu.showPortals) {
    menu.push({
      icon: 'portals',
      label: 'portals',
      href: `${data.apiUrl}/cl/v3/page/portalslijst?token=${auth.instanceId}`,
    })
  }

  if (supportMenu.showManual) {
    menu.push({
      icon: 'whiteLabelManual',
      label: 'instructionManual',
      href: t(`themeOptions.${themeNameCamelCase}.supportMenu.manual`),
    })
  }

  if (supportMenu.showSupportQuestion) {
    menu.push({
      icon: 'supportQuestion',
      label: 'supportQuestion',
      onClick: supportQuestionHandleClick,
    })
  }

  if (supportMenu.showTeamviewer) {
    menu.push({
      icon: 'teamviewer',
      label: 'teamviewer',
      href: t(`themeOptions.${themeNameCamelCase}.supportMenu.teamviewer`),
    })
  }

  return menu
}

const Container = styled.div`
  background: ${({ theme }) => theme.colors.brandFoxtrot};
  height: 100%;
  overflow-y: ${({ disableScroll }) => (disableScroll ? 'hidden' : 'scroll')};
  -webkit-overflow-scrolling: touch;

  ${media.desktop`
    padding: 0 ${({ theme, showActionBar }) => (showActionBar ? theme.widths.actionBarWidth : 0)} 0 ${({ theme }) => theme.widths.navigationBarWidth};
  `}

  @media print {
    padding: 0;
    overflow-y: visible;
    background-color: rgba(0, 0, 0, 0);
  }
`

const StyledNavigationContainer = styled.div`
  display: none;

  ${media.desktop`
    display: block;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    z-index: 10;
    width: ${({ theme }) => theme.widths.navigationBarWidth};
  `};

  @media print {
    display: none;
  }
`

const StyledMobileHeader = styled(MobileHeader)`
  display: flex;

  ${media.desktop`
    display: none;
  `};
`

const MobileMenuContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.brandBravo};
  display: block;
  height: calc(100% - ${({ theme }) => theme.heights.mobileHeaderHeight} + 1px);
  overflow: auto;
  position: fixed;
  top: calc(${({ theme }) => theme.heights.mobileHeaderHeight} - 1px);
  left: 0;
  width: 100%;
  z-index: 100;
  -webkit-overflow-scrolling: touch;

  ${media.desktop`
    display: none;
  `};
`

const StyledMobileMenu = styled(MobileMenu)`
  height: 100%;
`

const Main = styled.div`
  flex: 1;
  min-height: calc(100vh - ${({ theme }) => theme.heights.mobileHeaderHeight});

  ${media.desktop`
    min-height: 100vh;
  `};

  @media print {
    min-height: 0 !important;
  }
`

const PopupMenu = styled(Menu)`
  box-shadow: ${({ theme }) => theme.shadows.bottomRight};
  left: ${({ left }) => left}px;
  top: ${({ top }) => top}px;
  position: absolute;
  transform: translateY(-100%);
  z-index: 101;
`

const StyledActionBar = styled(ActionBar)`
  display: none;

  ${media.desktop`
    display: block;
    height: 100vh;
    right: 0;
    position: fixed;
    top: 0;
    width: ${({ theme }) => theme.widths.actionBarWidth};
    z-index: 9;
  `};

  @media print {
    display: none;
  }
`

const MobileActionBarButton = styled(Button)`
  bottom: 2em;
  border-radius: 2em;
  display: block;
  margin-right: ${({ theme }) => theme.sizings.lvl2};
  display: flex;
  align-items: center;
  width: ${({ theme }) => theme.sizings.lvl6};
  height: ${({ theme }) => theme.sizings.lvl6};
  position: fixed;
  right: 1em;
  z-index: 1310; // 10 indices above the overlay
  box-shadow: ${({ theme }) => theme.shadows.bottom};

  &:hover {
    background-color: ${({ theme }) => theme.colors.actionsStandard};
  }

  ${media.desktop`
    display: none;
  `};
`

function replaceTemplateStrings(templateString, data) {
  const templates = templateString?.match(/{(\w+)}/g)

  if (!templates) {
    return templateString
  }

  let replacedString = templateString

  templates.forEach((template) => {
    const dataFieldName = template.replace(/{|}/g, '')
    const replaceWith = data[dataFieldName] || ''
    replacedString = replacedString.replace(template, replaceWith)
  })

  return replacedString
}

function processMenuItems(items, data = {}) {
  return items.map((item) => ({
    ...item,
    icon: item.icon || item.id, // TODO: Some items from the backend have an "icon" prop, some don't. They need to align this.
    label: item.label,
    to: replaceTemplateStrings(item.link, data),
  }))
}

function AppLayout({
  auth,
  children,
  getForms,
  getMenus,
  getActionBarItems,
  getTodos,
  history,
  location,
  logout,
  mainMenu,
  pageTitle,
  openOverlay,
  settingsMenu,
}) {
  const newsItem = usePrioritizeNavigationBarNewsItem()
  const { showPopout, setShowPopout, notificationCount } =
    useNavigationBarNewsItem(newsItem)
  const theme = useContext(ThemeContext)
  const { themeNameCamelCase } = getAppTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const match = useRouteMatch()

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [mobileActionBarOpen, setMobileActionBarOpen] = useState(false)
  const [popupMenu, setPopupMenu] = useState('settings')
  const [popupMenuOpen, setPopupMenuOpen] = useState(false)
  const [popupMenuPosition, setPopupMenuPosition] = useState({
    left: 0,
    top: 0,
  })
  const selectedLocationId = useSelectedLocationId()[0]
  const actionBarItems = useSelector(
    (state) => state.data.actionBarItems && state.data.actionBarItems.data,
  )
  const supportRequestDetails = useSelector(
    (state) =>
      state.data.supportRequestDetails && state.data.supportRequestDetails.data,
  )

  let showActionBar = true

  if (!(auth.userDetails.pakket || auth.userDetails.marketview)) {
    showActionBar = false
  } else {
    showActionBar =
      !!actionBarItems?.main_actionbar ||
      !!actionBarItems?.contextmenu ||
      !!actionBarItems?.contextmenu_carshare
  }

  function openPopupMenu(event, menu) {
    event.stopPropagation()

    const { target } = event

    setPopupMenuPosition({
      left: target.offsetLeft,
      top: target.offsetTop + target.offsetHeight,
    })
    setPopupMenu(menu)
    setPopupMenuOpen(true)
  }

  function closePopupMenu() {
    setPopupMenuOpen(false)
  }

  useEffect(() => {
    getActionBarItems()
    getMenus()
    getTodos()
    getForms('general')
    dispatch(getSupportRequestDetails())
  }, [getActionBarItems, getForms, getMenus, getTodos, dispatch])

  function supportQuestionHandleClick() {
    const text = (
      <Trans
        i18nKey="supportQuestionIntro"
        values={{
          email: t(`themeOptions.${themeNameCamelCase}.supportEmail`),
          phone: formatPhoneNumber(
            t(`themeOptions.${themeNameCamelCase}.supportPhone`),
            t(`themeOptions.${themeNameCamelCase}.supportPhoneExtra`),
          ),
          dealerNumber: supportRequestDetails?.dealernr,
        }}
      >
        <Link href={supportRequestDetails.mailto}>
          {{
            phone: formatPhoneNumber(
              t(`themeOptions.${themeNameCamelCase}.supportPhone`),
            ),
          }}
        </Link>
      </Trans>
    )

    return openOverlay('form', {
      formCategory: 'support',
      storeFormId: 'supportQuestion',
      icon: 'supportQuestion',
      title: t('requestSupport'),
      text,
    })
  }

  const supportMenu = getSupportMenu(t, theme, auth, supportQuestionHandleClick)

  const placeholders = {
    vestiging_id: selectedLocationId,
  }
  const processedMainMenu = mainMenu
    ? processMenuItems(mainMenu, placeholders)
    : []
  const processedSettingsMenu = settingsMenu
    ? processMenuItems(settingsMenu)
    : []
  const translatedSettings = [
    ...(processedSettingsMenu && translateListItems(processedSettingsMenu, t)),
    {
      id: 'logout',
      icon: 'logout',
      label: t('logout'),
      onClick: () => logout(history.push),
    },
  ]
  const translatedSupport = translateListItems(supportMenu, t)
  const popupMenus = {
    settings: translatedSettings,
    support: translatedSupport,
  }

  const mobileMenuItems = [
    ...processedMainMenu,
    {
      id: 'news',
      icon: 'news',
      onClick: () => {
        window.open(
          'https://go-remarketing.com/actueel/?utm_source=ucc_app&utm_medium=aandacht_popup_nieuwsoverzicht_button',
          '_blank',
        )
      },
      label: t('goToNewsOverview'),
    },
    {
      icon: 'settings',
      items: translatedSettings,
      label: 'settings',
    },
    {
      icon: 'support',
      items: translatedSupport,
      label: 'support',
    },
  ]

  const translatedMobileMenuItems = translateListItems(mobileMenuItems, t)
  const translatedDesktopMenuItems =
    processedMainMenu && translateListItems(processedMainMenu, t)

  const disableContainerScroll = mobileMenuOpen || mobileActionBarOpen

  return (
    <Container
      disableScroll={disableContainerScroll}
      id="doc"
      onClick={closePopupMenu}
      showActionBar={showActionBar}
    >
      <Helmet
        title={`${pageTitle} - ${t(`themeOptions.${themeNameCamelCase}.productName`)}`}
      />
      <StyledNavigationContainer>
        <NavigationBar
          data-test-e2e="navigation-bar"
          items={translatedDesktopMenuItems}
          openPopupMenu={openPopupMenu}
          settings={popupMenus.settings}
          support={popupMenus.support}
          showPopout={showPopout}
          setShowPopout={setShowPopout}
          notificationCount={notificationCount}
          newsItem={newsItem}
        />
      </StyledNavigationContainer>
      <StyledMobileHeader
        menuOpen={mobileMenuOpen}
        openSearchOverlay={() => openOverlay('search')}
        openTodoOverlay={() => openOverlay('todos')}
        setMenuOpen={setMobileMenuOpen}
        data-test-e2e="mobile-header"
        showPopout={showPopout}
        setShowPopout={setShowPopout}
        notificationCount={notificationCount}
        newsItem={newsItem}
      />
      <Main>{children}</Main>
      {showActionBar && <StyledActionBar pathname={location.pathname} />}
      {mobileMenuOpen && (
        <MobileMenuContainer>
          <StyledMobileMenu
            closeMobileMenu={() => setMobileMenuOpen(false)}
            items={translatedMobileMenuItems}
            logout={() => logout(history.push)}
            data-test-e2e="main-menu-mobile"
          />
        </MobileMenuContainer>
      )}
      <ActionBar
        closeActionBar={() => setMobileActionBarOpen(false)}
        pathname={location.pathname}
        mobile
        mobileBarOpen={mobileActionBarOpen}
      />
      {popupMenuOpen && (
        <PopupMenu
          items={popupMenus[popupMenu]}
          left={popupMenuPosition.left}
          title={t(popupMenu)}
          top={popupMenuPosition.top}
          data-test-e2e={formatDataTestE2eAttr('popup-menu', popupMenu)}
        />
      )}
      {!mobileMenuOpen && match.path !== `${VALUATION}/:step` && (
        <MobileActionBarButton
          background="actionsStandard"
          icon={mobileActionBarOpen ? 'close' : 'lightingBolt'}
          iconSize="lg"
          level="minimal"
          onClick={() => setMobileActionBarOpen(!mobileActionBarOpen)}
        />
      )}
      <LayoutOverlays />
    </Container>
  )
}

AppLayout.propTypes = {
  auth: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  getForms: PropTypes.func.isRequired,
  getMenus: PropTypes.func.isRequired,
  getActionBarItems: PropTypes.func.isRequired,
  getTodos: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  mainMenu: PropTypes.array,
  pageTitle: PropTypes.string.isRequired,
  openOverlay: PropTypes.func.isRequired,
  settingsMenu: PropTypes.array,
}

AppLayout.defaultProps = {
  actionsMenu: null,
  mainMenu: null,
  settingsMenu: null,
}

export default AppLayout
