/* eslint-disable camelcase */

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'

import { getAppTheme } from 'utilities/utils'

import { AppThemes } from 'config/enums'

import Button from 'components/atoms/button'
import { useCarFileType } from 'hooks/use-car-file-type'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import ActionBarSection from './action-bar-section'
import Menu from './menu'

const Container = styled.aside`
  background-color: white;
  height: ${({ hasMobileLayout }) => (hasMobileLayout ? 'inherit' : '100vh')};
  position: relative;
  padding-bottom: ${({ theme }) => theme.sizings.lvl2};
  overflow: ${({ hasMobileLayout }) => (hasMobileLayout ? 'auto' : '')};
`

const ScrollableSection = styled.div`
  z-index: 5;
  transition: transform 0.3s;
  transform: translateY(${({ $translateY }) => $translateY}px);
`

const SlideButton = styled(Button)`
  align-items: center;
  box-shadow: ${({ theme }) => theme.colors.inactiveBackground} -8px 0px 4px -4px;
  display: flex;
  min-width: calc(${({ theme }) => theme.sizings.lvl6} + 2px);
  width: ${({ theme }) => theme.sizings.lvl6};
  border-radius: 0;
  position: fixed;
  padding: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  svg {
    transform: rotate(
      ${({ $overFlowDirection }) =>
        $overFlowDirection === 'down' ? 90 : -90}deg
    );
  }
`
function SideActionsMenu({
  className,
  appLevelItems,
  overviewLevelItems,
  detailsLevelItems,
  hasMobileLayout,
  appLevelHeading,
  overviewLevelHeading,
  detailsLevelHeading,
  onClose,
  ...restProps
}) {
  const { themeName } = getAppTheme()
  const uccLicense = useSelector(({ auth }) => auth?.userDetails?.pakket)
  const { t } = useTranslation()

  const { isCarFile, isRegularCarFile, isB2bCarFile, isCarShareCarFile } =
    useCarFileType()
  const containerRef = useRef()
  const innerContainerRef = useRef()

  const [hasOverflowInDirection, setHasOverflowInDirection] = useState({
    up: false,
    down: false,
  })
  const [translateY, setTranslateY] = useState(0)

  const setOverflowState = useCallback(
    (totalItemsLength) => {
      if (containerRef.current && innerContainerRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect()
        const innerContainerRect =
          innerContainerRef.current.getBoundingClientRect()

        const isOverFlowing = innerContainerRect.height > containerRect.height
        setHasOverflowInDirection({
          up: isOverFlowing ? translateY < 0 : false,
          down: isOverFlowing ? translateY === 0 : false,
        })
      }
    },
    [translateY],
  )

  const recalculateOverflow = useCallback(() => {
    setTranslateY(0)
    setOverflowState()
  }, [setOverflowState])

  useEffect(() => {
    // The refs don't exist for a small tablist so don't execute
    // this effect in that case
    const totalItemsLength = appLevelItems.length + overviewLevelItems.length
    if (!hasMobileLayout && totalItemsLength > 0) {
      if (containerRef.current && innerContainerRef.current) {
        setOverflowState()
        window.addEventListener('resize', recalculateOverflow)
      }
    }

    return () => {
      if (
        !hasMobileLayout // &&
      ) {
        window.removeEventListener('resize', recalculateOverflow)
      }
    }
  }, [
    hasMobileLayout,
    appLevelItems,
    overviewLevelItems,
    translateY,
    setOverflowState,
    recalculateOverflow,
  ])

  if (
    !uccLicense &&
    !(Array.isArray(overviewLevelHeading) && overviewLevelHeading.length) &&
    !(Array.isArray(detailsLevelItems) && detailsLevelItems.length)
  ) {
    return null
  }

  const handleScroll = () => {
    if (hasOverflowInDirection.up) {
      setTranslateY(0)
    } else {
      const innerContainerRect =
        innerContainerRef.current.getBoundingClientRect()
      const containerRect = containerRef.current.getBoundingClientRect()
      setTranslateY(-(innerContainerRect.height - containerRect.height))
    }
  }

  const mainLevelItems = [
    ...appLevelItems,
    ...(hasMobileLayout ||
    (!hasMobileLayout &&
      // do not show overview level items for certain type of car files on desktop:
      (!isCarFile || isRegularCarFile || isB2bCarFile || isCarShareCarFile))
      ? overviewLevelItems
      : []),
  ]

  return (
    <Container
      className={className}
      hasMobileLayout={hasMobileLayout}
      ref={containerRef}
      {...restProps}
    >
      {hasMobileLayout ? (
        <>
          {uccLicense && mainLevelItems.length > 0 && (
            <Menu
              backgroundColor="brandBravo"
              color="brandGolf"
              items={mainLevelItems}
              title={appLevelHeading}
              hasMobileLayout
              closeMenu={onClose}
            />
          )}

          {detailsLevelItems.length > 0 && (
            <Menu
              backgroundColor="brandGolf"
              items={detailsLevelItems}
              title={detailsLevelHeading}
              hasMobileLayout
              closeMenu={onClose}
            />
          )}
        </>
      ) : (
        <>
          <ScrollableSection ref={innerContainerRef} $translateY={translateY}>
            {uccLicense ? (
              <ActionBarSection
                backgroundColor="brandBravo"
                iconDefaultColor="textOnDarkBackground"
                iconHoverColor={
                  themeName === AppThemes.AUTOPRESENTATIE ||
                  themeName === AppThemes.TODRIVE
                    ? 'textOnDarkBackground'
                    : 'brandGolf'
                }
                items={mainLevelItems}
              />
            ) : null}
            {(!isCarFile ||
              isRegularCarFile ||
              isB2bCarFile ||
              isCarShareCarFile) && (
              <ActionBarSection
                backgroundColor="brandGolf"
                iconDefaultColor={
                  themeName === AppThemes.TODRIVE ? 'actionsStandard' : 'text'
                }
                iconHoverColor="textOnDarkBackground"
                items={detailsLevelItems}
              />
            )}
          </ScrollableSection>
          {(hasOverflowInDirection.down || hasOverflowInDirection.up) && (
            <SlideButton
              onClick={handleScroll}
              $overFlowDirection={hasOverflowInDirection.down ? 'down' : 'up'}
              aria-label={
                hasOverflowInDirection.down
                  ? t('actionBar.scrollToBottom')
                  : t('actionBar.scrollToTop')
              }
              icon="chevronRight"
              level="standard"
            />
          )}
        </>
      )}
    </Container>
  )
}

SideActionsMenu.propTypes = {
  className: PropTypes.string,
  appLevelItems: PropTypes.array.isRequired,
  appLevelHeading: PropTypes.string,
  overviewLevelItems: PropTypes.array.isRequired,
  overviewLevelHeading: PropTypes.string,
  detailsLevelItems: PropTypes.array.isRequired,
  detailsLevelHeading: PropTypes.string,
  hasMobileLayout: PropTypes.bool,
  /**
   * What to do onClose, will be triggered by MenuItem children
   */
  onClose: PropTypes.func,
}

SideActionsMenu.defaultProps = {
  className: null,
  hasMobileLayout: false,
  appLevelHeading: null,
  overviewLevelHeading: null,
  detailsLevelHeading: null,
  onClose: () => {},
}

export default SideActionsMenu
