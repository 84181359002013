import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { ThemeContext } from 'styled-components/macro'

import { media } from 'utilities/styled'
import { getAppTheme } from 'utilities/utils'

import { AppThemes } from 'config/enums'

import Button from 'components/atoms/button'
import Counter from 'components/atoms/counter'
import FramedImage from 'components/atoms/framed-image'
import Link from 'components/atoms/link'
import DelphiAIChatBotButton from 'components/molecules/delphi-ai-chat-bot-button'
import NavigationBarNews from 'components/molecules/navigation-bar-news'
import Searchbox from 'components/molecules/searchbox'
import Menu from 'components/organisms/menu'
import { useDispatch, useSelector } from 'react-redux'
import { closeSearchbox, openOverlay, openSearchbox } from 'redux/actions/ui'
import { css } from 'styled-components'

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.brandBravo};
  display: flex;
  flex-direction: column;
  height: inherit;
  overflow-y: auto;
`

const Header = styled.div`
  margin: 0 24px;
  min-height: auto;
`

const StyledMenuContainer = styled.div`
  min-height: auto;
  flex: 1 0 auto;
`

const SharedAppLogoStyles = css`
  margin: ${({ theme }) => theme.sizings.lvl4} auto 0;
`

const UccLogo = styled(FramedImage)`
  ${SharedAppLogoStyles}
  height: 56px;
  width: 100px;
`

const AutopresentatieLogo = styled(FramedImage)`
  ${SharedAppLogoStyles}
  height: auto;
  width: 100%;
`

const ToDriveLogo = styled(FramedImage)`
  ${SharedAppLogoStyles}
  height: 26px;
  width: 130px;
`

const StyledQuickActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${({ theme }) => theme.sizings.lvl2} 0;
  position: relative;
  left: -${({ theme }) => theme.sizings.lvl0};
  width: calc(100% + ${({ theme }) => theme.sizings.lvl1});
`

export const QuickActionButton = styled(Button)`
  background-color: ${({ theme }) =>
    theme.colors.mainNavActionButtonBackground};
  border-radius: 50%;
  width: ${({ theme }) => theme.sizeByFactor(5.5)};
  height: ${({ theme }) => theme.sizeByFactor(5.5)};
  padding: 0;
  flex: 0 0 auto;
  min-width: 0;
`

const StyledSearchbox = styled(Searchbox)`
  position: relative;
  width: calc(100% + 2 * ${({ theme }) => theme.sizeByFactor(3)});
  left: -${({ theme }) => theme.sizeByFactor(3)};
  margin-top: -${({ theme }) => theme.sizings.lvl1};
  border-right: 1px solid ${({ theme }) => theme.colors.brandCharlie};
`
const TodosContainer = styled.div`
  position: relative;
`

const StyledCounter = styled(Counter)`
  position: absolute;
  right: -${({ theme }) => theme.sizings.lvl0};
  top: -${({ theme }) => theme.sizings.lvl0};
`

const Line = styled.div`
  background-color: ${({ theme }) => theme.colors.mainNavLine};
  height: 1px;
  margin: ${({ theme }) => theme.sizings.lvl3} 0;
  position: relative;
  left: -${({ theme }) => theme.sizings.lvl0};
  width: calc(100% + ${({ theme }) => theme.sizings.lvl1});

  @media (max-height: 900px) {
    margin: ${({ theme }) => theme.sizings.lvl2} 0;
  }
`

const Footer = styled.footer`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 24px;
  /* margin-top: auto; */
  margin-bottom: 32px;
  gap: ${({ theme }) => theme.sizings.lvl2};
  flex-shrink: 0;
  border-top: 1px solid ${({ theme }) => theme.colors.mainNavLine};

  @media (max-height: 900px) {
    gap: ${({ theme }) => theme.sizings.lvl1};
  }
`

const FooterButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-shrink: 0;
  margin-top: ${({ theme }) => theme.sizings.lvl2};

  /**patching the minimal button variant's hover with custom styling */
  button,
  a {
    &:focus,
    &:hover {
      background-color: ${({ theme }) => theme.colors.actionsStandardText};
      color: ${({ theme }) => theme.colors.text};

      /* using * selector for the svg icon, otherwise it does not overrule
      the orginal css selector. Required for the focus selector to work. */
      * {
        fill: currentColor;
      }
    }
  }
`

const LogoLink = styled(Link)`
  display: flex;
  justify-content: center;
`

const StyledMenu = styled(Menu)`
  ${media.tablet`
    a {
      padding-left: ${({ theme }) => theme.sizeByFactor(3)};
    }
  `}
`

function NavigationBar({
  className,
  items,
  openPopupMenu,
  showPopout,
  actionsMenuItems,
  setShowPopout,
  notificationCount,
  newsItem,
  ...restProps
}) {
  const { themeName } = getAppTheme()
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const searchboxVisible = useSelector((state) => state.ui.searchbox)

  const { logoMark } = theme
  const showActionsMenu =
    Array.isArray(actionsMenuItems) && actionsMenuItems.length > 0
  const hasAccessToDelphiAiChatBot = useSelector(
    (state) => !!state.auth?.userDetails?.chatbot,
  )

  const handleSearchBoxPopOut = () => {
    if (!searchboxVisible) {
      dispatch(openSearchbox())
    } else {
      dispatch(closeSearchbox())
    }
  }

  const todosCount = useSelector(
    (state) => state?.data?.todos?.data?.count || 0,
  )
  const openTodoOverlay = () => {
    dispatch(openOverlay('todos'))
  }

  return (
    <Container className={className} {...restProps}>
      <Header>
        <LogoLink to="/">
          {themeName === AppThemes.UCC && (
            <UccLogo
              alt="UCC"
              src={logoMark}
              $showActionsMenu={showActionsMenu}
            />
          )}

          {themeName === AppThemes.AUTOPRESENTATIE && (
            <AutopresentatieLogo alt="UCC" src={logoMark} />
          )}

          {themeName === AppThemes.TODRIVE && (
            <ToDriveLogo alt="UCC" src={logoMark} />
          )}
        </LogoLink>
        <Line />
        <StyledQuickActions>
          <QuickActionButton
            icon="search"
            level="standard"
            iconHeight={20}
            iconWidth={20}
            noIconSpacing
            onClick={handleSearchBoxPopOut}
          />
          {theme.showNewsFeed && (
            <NavigationBarNews
              showPopout={showPopout}
              setShowPopout={setShowPopout}
              notificationCount={notificationCount}
              newsItem={newsItem}
            />
          )}
          <TodosContainer>
            <QuickActionButton
              icon="todo"
              level="standard"
              iconHeight={20}
              iconWidth={20}
              noIconSpacing
              aria-label={t('todos')}
              onClick={openTodoOverlay}
            />
            {!!todosCount && <StyledCounter count={todosCount} />}
          </TodosContainer>
        </StyledQuickActions>
        {searchboxVisible && <StyledSearchbox />}
        <Line />
      </Header>
      <StyledMenuContainer data-test-e2e="navigation-bar">
        <StyledMenu
          backgroundColor="brandBravo"
          color="brandGolf"
          items={items}
          data-test-e2e="main-menu"
        />
      </StyledMenuContainer>
      <Footer data-test-e2e="footer">
        <FooterButtons data-test-e2e="footer-buttons">
          <Button
            testId="supportButton"
            icon="support"
            level="minimal"
            onClick={(e) => openPopupMenu(e, 'support')}
            data-test-e2e="button-support"
          />
          <Button
            testId="settingsButton"
            icon="settings"
            level="minimal"
            onClick={(e) => openPopupMenu(e, 'settings')}
            data-test-e2e="button-settings"
          />
          {hasAccessToDelphiAiChatBot && <DelphiAIChatBotButton />}
        </FooterButtons>
      </Footer>
    </Container>
  )
}

NavigationBar.propTypes = {
  actionsMenuItems: PropTypes.array,
  className: PropTypes.string,
  items: PropTypes.array.isRequired,
  logout: PropTypes.func.isRequired,
  openPopupMenu: PropTypes.func.isRequired,
  showPopout: PropTypes.bool.isRequired,
  setShowPopout: PropTypes.func.isRequired,
  notificationCount: PropTypes.number.isRequired,
  newsItem: PropTypes.shape({
    img: PropTypes.string,
    title: PropTypes.string.isRequired,
    intro: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }),
}

NavigationBar.defaultProps = {
  actionsMenuItems: null,
  className: null,
  newsItem: null,
}

export default NavigationBar
