import ImageCarousel from 'components/molecules/image-carousel'
import Overlay from 'components/molecules/overlay'
import OverlaysContainer from 'components/redux-containers/overlays-container'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'

const ImageOverlay = styled(Overlay)`
  height: 100vh;
`

const StyledImageCarousel = styled(ImageCarousel)`
  height: 100vh;
`

function LayoutOverlays({ carAssets, closeImageOverlay, closeSearchbox, ui }) {
  const b2bCarImages = useSelector((state) =>
    Array.isArray(state?.data?.b2bCarFileAssets?.data)
      ? state.data.b2bCarFileAssets.data.filter(({ type }) => type === 'image')
      : [],
  )
  const carImages =
    ui.imagesSetType === 'b2b-car-file'
      ? b2bCarImages
      : carAssets &&
        carAssets.data &&
        carAssets.data.filter(({ type }) => type === 'image')

  return (
    <>
      {ui.imageOverlayOpen && (
        <ImageOverlay close={closeImageOverlay}>
          {carImages && (
            <StyledImageCarousel
              images={carImages}
              initialSlide={ui.imageOverlayImageIdx}
              fullHeight
              arrows
            />
          )}
        </ImageOverlay>
      )}
      {ui.overlay && <OverlaysContainer overlay={ui.overlay} />}
    </>
  )
}

LayoutOverlays.propTypes = {
  carAssets: PropTypes.object,
  closeImageOverlay: PropTypes.func.isRequired,
  closeSearchbox: PropTypes.func.isRequired,
  ui: PropTypes.object.isRequired,
}

LayoutOverlays.defaultProps = {
  carAssets: null,
}

export default LayoutOverlays
