import Menu from 'components/organisms/menu'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.brandBravo};
  width: 100%;
`

const Line = styled.div`
  background-color: ${({ theme }) => theme.colors.mainNavLine};
  width: calc(100% - 48px);
  height: 1px;
  margin: 0 24px 16px;
`

const StyledMenu = styled(Menu)``

function MobileMenu({
  className,
  closeMobileMenu,
  items,
  logout,
  ...restProps
}) {
  return (
    <Container className={className} {...restProps}>
      <Line />
      <StyledMenu
        closeMenu={closeMobileMenu}
        items={items.filter((item) => !item.hideMobile)}
        backgroundColor="brandBravo"
        color="textOnDarkBackground"
        hasMobileLayout
      />
    </Container>
  )
}

MobileMenu.propTypes = {
  actionsMenuItems: PropTypes.array.isRequired,
  className: PropTypes.string.isRequired,
  closeMobileMenu: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  logout: PropTypes.func.isRequired,
}

export default MobileMenu
