import PageHeader from 'components/organisms/page-header'
import AppLayoutContainer from 'components/redux-containers/app-layout-container'
import React, { useContext, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'styled-components'
import styled from 'styled-components/macro'
import { media } from 'utilities/styled'

const Container = styled.div`
  background: ${({ theme }) => theme.colors.brandFoxtrot};
  padding: 0 20px;
  min-height: calc(100vh - 60px);

  ${media.desktop`
    padding: 0 60px;
  `};
`

function DelphiAiChatBot() {
  const { t } = useTranslation()
  const ref = useRef()
  const containerRef = useRef()
  const theme = useContext(ThemeContext)

  const sizeChatBoxArea = () => {
    if (ref.current && containerRef.current) {
      const isSmallerThanDesktop = window.innerWidth <= theme.metrics.desktop
      const bottomSpacing = parseInt(
        theme.sizeByFactor(isSmallerThanDesktop ? 25 : 20),
        10,
      )
      const boundingBox = containerRef.current.getBoundingClientRect()
      const height = boundingBox.height - bottomSpacing
      ref.current.style.height = `${height}px`
    }
  }

  useEffect(() => {
    /**
     * This a modified version of the script provided by Delphi AI.
     * This is done, because the script is set up to be inserted in the head of the document.
     * and should be loaded before the body is loaded, which is not an option in React.
     * There we extracted the functional part of the script and added it to the useEffect hook.
     */
    window.delphi = { ...(window.delphi ?? {}) }
    window.delphi.page = {
      config: '18abb9a9-02e0-4779-98b3-8908050d8c04',
      container: {
        width: '100%',
        height: '400px',
      },
    }
    const script = document.createElement('script')
    script.src = 'https://embed.delphi.ai/bundle.js'
    script.type = 'text/javascript'
    script.async = true
    script.defer = true
    document.body.appendChild(script)
    script.addEventListener('load', sizeChatBoxArea)
    window.addEventListener('resize', sizeChatBoxArea)

    return () => {
      script.removeEventListener('load', sizeChatBoxArea)
      document.body.removeChild(script)
      window.removeEventListener('resize', sizeChatBoxArea)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AppLayoutContainer pageTitle={t('delphi-ai-chatbot.pageTitle')}>
      <Container ref={containerRef}>
        <PageHeader
          mainTitle={t('delphi-ai-chatbot.mainTitle')}
          subTitle={t('delphi-ai-chatbot.subTitle')}
        />
        <div id="delphi-container" ref={ref}>
          Loading the chatbot
        </div>
      </Container>
    </AppLayoutContainer>
  )
}

DelphiAiChatBot.propTypes = {}

export default DelphiAiChatBot
