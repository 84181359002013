import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components/macro'

import { useMediaLayout } from 'use-media'

import Button from 'components/atoms/button'
import Typography from 'components/molecules/typography'

const StyledDialog = styled(Dialog)`
  .MuiDialog-container {
    align-items: flex-end;
  }

  .MuiPaper-root {
    background: ${({ theme }) => theme.colors.isolatedBackground};
    box-shadow: ${({ theme }) => theme.shadows.modal};
    margin: 0;
    width: 100%;
  }

  .MuiBackdrop-root {
    background: ${({ theme }) => theme.colors.brandAlfa}cc;
  }

  .MuiDialogContent-root {
    background-color: white;
    padding: ${({ theme }) => theme.sizings.lvl2};
  }
`

const DialogTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.sizings.lvl1};
`

const StyledTypography = styled(Typography)`
  margin: 0;
`

const StyledCloseButton = styled(Button)`
  margin-left: auto;
`

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

/**
 * This component is used to give the user multiple options to continue a flow
 */
function Modal({
  children,
  className,
  closeHandler,
  disableBackdropClick,
  open,
  title,
  showCloseButton,
  ...restProps
}) {
  const theme = useContext(ThemeContext)
  const isMobile = useMediaLayout({ maxWidth: theme.metrics.tablet - 1 })

  return (
    <StyledDialog
      PaperProps={{ square: true }}
      open={open}
      className={className}
      disableBackdropClick={disableBackdropClick}
      onClose={closeHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={isMobile ? Transition : undefined}
      {...restProps}
    >
      <DialogContent data-test-e2e="dialog-content">
        {(title || (showCloseButton && closeHandler)) && (
          <DialogTitle>
            {title && (
              <StyledTypography type="Level5Heading">{title}</StyledTypography>
            )}
            {showCloseButton && closeHandler && (
              <StyledCloseButton
                level="minimal"
                icon="close"
                iconColor="darkestBackground"
                onClick={closeHandler}
              />
            )}
          </DialogTitle>
        )}
        {children}
      </DialogContent>
    </StyledDialog>
  )
}
Modal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  /** Event handler for closing the modal */
  closeHandler: PropTypes.func,
  /** If true, clicking the backdrop will not fire the onClose callback. */
  disableBackdropClick: PropTypes.bool,
  /** Open / closes state boolean */
  open: PropTypes.bool,
  /** Modal title */
  title: PropTypes.node,
  /** Shows a close button in the modal */
  showCloseButton: PropTypes.bool,
}

Modal.defaultProps = {
  children: null,
  className: null,
  closeHandler: null,
  disableBackdropClick: false,
  open: false,
  title: null,
  showCloseButton: false,
}

export default Modal
