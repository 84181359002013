/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { reset } from 'redux-form'
import styled from 'styled-components/macro'

import { getForms } from 'redux/actions/forms'

import * as routes from 'config/routes'

import { withApolloV4Provider } from 'utilities/apollo'
import { getTopLevelPath, translateListItems } from 'utilities/utils'

import { useCarFileType } from 'hooks/use-car-file-type'

import Modal from 'components/molecules/modal'
import { getActionMenuItems } from 'redux/actions/data'
import SideActionsMenu from './side-actions-menu'

const StyledModal = styled(Modal)`
  .MuiDialogContent-root {
    padding: 0;
  }
`

function replaceTemplateStrings(templateString, data) {
  const templates = templateString.match(/{(\w+)}/g)

  if (!templates) {
    return templateString
  }

  let replacedString = templateString

  templates.forEach((template) => {
    const dataFieldName = template.replace(/{|}/g, '')
    const replaceWith = data[dataFieldName] || ''
    replacedString = replacedString.replace(template, replaceWith)
  })

  return replacedString
}

function getOverlayOnClick(
  label,
  forms,
  icon,
  overlay,
  openOverlay,
  postDynamicEndpoint,
  data,
  t,
) {
  const { type, form_category, form_id } = overlay

  if (type) {
    return () => openOverlay(type, { data })
  }

  const formsCategory = forms[form_category]
  const form =
    formsCategory && formsCategory.data && formsCategory.data[form_id]

  // The default text for the submit button is 'Save changes'. Some overlays might require a
  // different text in that button. This piece of code checks the `overlaySubmitButtonTexts` object
  // in the translations file to see if there is a translation for that text available. It uses
  // form_category and form_id to do that. See the translations file for more info.
  let submitText = t('saveChanges')
  if (form_category && form_id) {
    const translationKey = `overlaySubmitButtonTexts.${form_category}.${form_id}`
    const translatedSubmitText = t(translationKey)
    if (translatedSubmitText !== translationKey) {
      submitText = translatedSubmitText
    }
  }

  return () =>
    openOverlay('form', {
      data,
      icon,
      formCategory: form_category,
      formId: form_id,
      storeFormId: form_id,
      onSubmit: (formState) =>
        postDynamicEndpoint({
          endpoint: form.endpoint,
          reload: form.reload,
          redirect: form.redirect,
          ...formState,
        }),
      submitText,
      title: t(label),
      enableReinitialize: true,
    })
}

function getEndpointOnClick(getDynamicEndpoint, endpoint, reload) {
  return () => getDynamicEndpoint({ endpoint, reload })
}

// Replace template variables in links and endpoints with actual data
export function processItems(
  items,
  data,
  forms,
  getDynamicEndpoint,
  openOverlay,
  postDynamicEndpoint,
  t,
) {
  return items?.map((item) => {
    const { endpoint, href, icon, label, link, overlay, reload } = item
    let processedItem = {
      ...item,
    }

    if (link && data) {
      const processedLink = replaceTemplateStrings(link, data)

      processedItem = {
        ...processedItem,
        to: processedLink,
      }
    }

    if (endpoint && data) {
      const processedEndpoint = replaceTemplateStrings(endpoint, data)
      const onClickEndpoint = getEndpointOnClick(
        getDynamicEndpoint,
        processedEndpoint,
        reload,
      )

      processedItem = {
        ...processedItem,
        onClick: onClickEndpoint,
      }
    }

    if (href && data) {
      const processedHref = replaceTemplateStrings(href, data)

      processedItem = {
        ...processedItem,
        href: processedHref,
      }
    }

    if (overlay && data) {
      const onClickOverlay = getOverlayOnClick(
        label,
        forms,
        icon,
        overlay,
        openOverlay,
        postDynamicEndpoint,
        data,
        t,
      )
      processedItem = {
        ...processedItem,
        onClick: onClickOverlay,
      }
    }

    if (Array.isArray(processedItem.items) && processedItem.items.length) {
      processedItem = {
        ...processedItem,
        items: processItems(
          processedItem.items,
          data,
          forms,
          getDynamicEndpoint,
          openOverlay,
          postDynamicEndpoint,
          t,
        ),
      }
    }

    return processedItem
  })
}

/**
 * filters out items that should be hidden/not displayed on mobile
 * recursively.
 * @param {Array} items
 */
function hideItemsForMobile(items) {
  const filteredItems = items.filter((item) => {
    if (item.hideMobile) {
      return false
    }
    // check if all options in a submenu should be hidden,
    // if so might as well hide the parent item too:
    if (
      Array.isArray(item.items) &&
      item.items.every((subItem) => subItem.hideMobile)
    ) {
      return false
    }
    return true
  })

  // then do the same for the subItems, recursively:
  return filteredItems.map((item) => {
    if (Array.isArray(item.items)) {
      return hideItemsForMobile(item.items)
    }
    return item
  })
}

function ActionBar({
  data,
  className,
  clearData,
  dynamicEndpoint,
  getDynamicEndpoint,
  forms,
  history,
  items,
  mobile,
  openOverlay,
  pathname,
  postDynamicEndpoint,
  closeActionBar,
  mobileBarOpen,
}) {
  const { isCarShareCarFile } = useCarFileType()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const dynamicEndpointData =
    dynamicEndpoint && dynamicEndpoint.data && dynamicEndpoint.data.data

  /**
   * The overviewLevelItems contain some items that open up rdw forms.
   * To make sure they are loaded on whatever page is loaded they are retrieved here
   */
  useEffect(() => {
    dispatch(getForms('rdw'))
  }, [dispatch])

  useEffect(() => {
    dispatch(reset('rdw'))
  }, [dispatch, history])

  // Whenever the dynamicEndpoint action results in a response that
  // contains an auto_id (which is not the current one we're viewing
  // if we are in the carfile view) we conclude a new car has been
  // created by that call and redirect to its carfile with the following
  // effect
  useEffect(() => {
    if (!data || !dynamicEndpointData) {
      return
    }

    const dataCarId = data.auto_id
    const dynamicCarId = dynamicEndpointData.auto_id
    if (dynamicCarId && dynamicCarId !== dataCarId) {
      clearData('dynamicEndpoint')
      history.push(`${routes.CAR_FILE}/${dynamicCarId}`)
    }
  }, [clearData, data, dynamicEndpointData, history])

  // For some dynamicEndpoints it is necessary to reload the data visible
  // in the currently active view. Since this most probably requires a architectural
  // change to the frontend we have decided to solve this for now with a hard refresh
  // @TODO Refactor this functionality. A ticket has been added to scrumwise that
  // explains the issue and suggests a couple of solutions:
  // https://scrumwise.com/scrum/#/backlog-item/1311-fed-refactor-reload-functionaliteit-voor-action-bar-items/id-171135-2432-67
  const previousLoading = useRef()

  useEffect(() => {
    if (!dynamicEndpoint) {
      return
    }

    if (previousLoading.current && !dynamicEndpoint.loading) {
      if (dynamicEndpoint.payload.redirect) {
        history.push(dynamicEndpoint.payload.redirect)
      } else if (dynamicEndpoint.payload.reload) {
        if (typeof dynamicEndpoint.payload.reload === 'string') {
          history.push(dynamicEndpoint.payload.reload)
        }
        window.location.reload()
      }
    }
    previousLoading.current = dynamicEndpoint.loading
  }, [dynamicEndpoint, history])

  const translatedRdwItems =
    items && translateListItems(items.main_actionbar, t)
  const translatedDynamicItems =
    items &&
    translateListItems(
      isCarShareCarFile ? items.contextmenu_carshare : items.contextmenu,
      t,
    )

  const visibleItemsForPath =
    translatedDynamicItems &&
    translatedDynamicItems.filter(
      (item) => item.show.view === getTopLevelPath(pathname),
    )

  // Action bar items may have a conditions field in their show field
  // which determines whether an action bar should be visible based on
  // the state in the front end. The following code filters out items
  // that don't meet these conditions.
  const visibleItemsForState =
    visibleItemsForPath &&
    visibleItemsForPath.filter((item) => {
      if (item.show && item.show.conditions && data) {
        const conditionFields = item.show.conditions
        const conditionsMet = conditionFields.reduce((metResult, condition) => {
          const allConditionsMet =
            metResult ||
            Object.entries(condition).reduce(
              (allMetResult, [conditionKey, conditionValues]) =>
                // @ TODO Temporary workaround: whenever the conditionValues
                // is not an array it is used to check wheter an item in
                // the data exists or not.
                allMetResult || Array.isArray(conditionValues)
                  ? conditionValues.indexOf(data[conditionKey]) !== -1
                  : !!data[conditionKey],
              false,
            )
          return metResult || allConditionsMet
        }, false)

        return conditionsMet
      }

      return true
    })

  const processedRdwItems =
    translatedRdwItems &&
    processItems(
      translatedRdwItems,
      data,
      forms,
      getDynamicEndpoint,
      openOverlay,
      postDynamicEndpoint,
      t,
    )

  const processedDynamicItems =
    visibleItemsForState &&
    processItems(
      visibleItemsForState,
      data,
      forms,
      getDynamicEndpoint,
      openOverlay,
      postDynamicEndpoint,
      t,
    )

  const mainCarActions = useSelector((state) => {
    const actions = Array.isArray(state?.data?.menus?.data?.actions)
      ? state.data.menus.data.actions
      : []
    return actions.map((action) => ({ ...action, to: action.link }))
  })
  useEffect(() => {
    !mainCarActions && dispatch(getActionMenuItems())
  }, [mainCarActions, dispatch])

  if (mobile) {
    const mobileProcessedRdwItems = processedRdwItems?.length
      ? hideItemsForMobile(processedRdwItems).flatMap((item) => item)
      : []
    const mobileProcessedDynamicItems = processedDynamicItems?.length
      ? hideItemsForMobile(processedDynamicItems).flatMap((item) => item)
      : []
    const overviewLevelItems = mobileProcessedRdwItems?.length
      ? mobileProcessedRdwItems
      : []
    const detailsLevelItems = mobileProcessedDynamicItems?.length
      ? mobileProcessedDynamicItems
      : []

    return (
      <StyledModal
        open={mobileBarOpen}
        fullScreen
        closeHandler={closeActionBar}
      >
        <SideActionsMenu
          className={className}
          appLevelItems={mainCarActions}
          overviewLevelHeading={t('rdwActions')}
          overviewLevelItems={overviewLevelItems}
          detailsLevelHeading={t('carFileActions')}
          detailsLevelItems={detailsLevelItems}
          hasMobileLayout
          onClose={closeActionBar}
          data-test-e2e="side-action-menu-mobile"
        />
      </StyledModal>
    )
  }

  return (
    <SideActionsMenu
      className={className}
      appLevelItems={mainCarActions}
      overviewLevelItems={processedRdwItems}
      detailsLevelItems={processedDynamicItems}
      data-test-e2e="side-action-menu"
    />
  )
}

ActionBar.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string,
  clearData: PropTypes.func.isRequired,
  /** provided by wrapping HOC: */
  closeActionBar: PropTypes.func,
  dynamicEndpoint: PropTypes.object,
  forms: PropTypes.object,
  getDynamicEndpoint: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  items: PropTypes.object,
  mobile: PropTypes.bool,
  openOverlay: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  postDynamicEndpoint: PropTypes.func.isRequired,
  mobileBarOpen: PropTypes.bool,
}

ActionBar.defaultProps = {
  className: null,
  data: null,
  dynamicEndpoint: null,
  forms: null,
  items: null,
  mobile: false,
  mobileBarOpen: false,
  closeActionBar: () => {},
}

export default withApolloV4Provider(ActionBar)
