import gql from 'graphql-tag'

export const carChecklist = gql`
  fragment carChecklist on Auto {
    id
    vin
    kenteken
    merk
    modelserie
    type
    km_binnen
    km_tax
    jaardeel1
    maanddeel1
    firstCarimage {
      id
      mediaUri
    }
  }
`
