import {
  AppThemesInternationalization,
  CarFileStatusEnumUpperCase,
} from 'config/enums'

const metrics = {
  hd: 1900,
  tv: 1440,
  desktop: 1120,
  tablet: 768,
  phone: 600,
}

const baseSize = 8
const baseFontSize = '14px'
const smallFontSize = '12px'
const baseLineHeight = '18px'
const baseFontWeight = 300

const widths = {
  navigationBarWidth: `${25 * baseSize}px`,
  gutter: `${5 * baseSize}px`,
  actionBarWidth: '50px',
  maxFieldWidth: '400px',
}

const heights = {
  mobileHeaderHeight: '60px',
}

const sizeByFactor = (factor) => `${factor * baseSize}px`

const sizings = {
  lvl0: sizeByFactor(0.5),
  lvl1: sizeByFactor(1),
  lvl2: sizeByFactor(2),
  lvl3: sizeByFactor(3),
  lvl4: sizeByFactor(4),
  lvl5: sizeByFactor(5),
  lvl6: sizeByFactor(6),
  lvl7: sizeByFactor(7),
  lvl8: sizeByFactor(8),
}

const chartColorScheme = {
  orange: '#FF5D00', // orange
  softRed: '#E40000',
  darkBlue: '#113678',
  lightBlue: '#56A2DE',
  grassyGreen: '#20BF55', // colour used for when something is ok.
  plainYellow: '#FFBD00', // yellow
  intenseOrange: '#FF6400', // orange
  intenseRed: '#BA0000', // red
  intensePurple: '#B61DAB', // purple
}

const checklistsColorScheme = {
  [CarFileStatusEnumUpperCase.lopend]: '#9bcbf0',
  [CarFileStatusEnumUpperCase.verwacht]: '#56a2de',
  [CarFileStatusEnumUpperCase.binnen]: '#2e76ae',
  [CarFileStatusEnumUpperCase.verkocht]: '#111857',
  [CarFileStatusEnumUpperCase.afgeleverd]: '#111857',
}

const ucc = {
  productName: `themeOptions.${AppThemesInternationalization.AUTOPRESENTATIE}.productName`,
  login: {
    backgroundImage: '/images/ucc-login-bg.jpg',
    showMadeThanksTo: false,
    payoff: `themeOptions.${AppThemesInternationalization.UCC}.login.payoff`,
    payoffForceLowercase: false,
    showCopyrightNotice: true,
    copyrightNotice: `themeOptions.${AppThemesInternationalization.AUTOPRESENTATIE}.login.copyrightNotice`,
  },
  showNewsFeed: true,
  logo: '/images/go-logo-2024.svg',
  logoMark: '/images/go-logo-mark.svg',
  faviconsFolder: '/images/favicons-go',
  supportEmail: `themeOptions.${AppThemesInternationalization.AUTOPRESENTATIE}.supportEmail`,
  supportEmailMarketview: `themeOptions.${AppThemesInternationalization.AUTOPRESENTATIE}.supportEmailMarketview`,
  supportPhone: `themeOptions.${AppThemesInternationalization.AUTOPRESENTATIE}.supportPhone`,
  supportPhoneExtra: `themeOptions.${AppThemesInternationalization.AUTOPRESENTATIE}.supportPhoneExtra`,
  supportMenu: {
    showPortals: true,
    showSupportQuestion: true,
    showManual: false,
    showTeamviewer: true,
    teamviewer: `themeOptions.${AppThemesInternationalization.AUTOPRESENTATIE}.supportMenu.teamviewer`,
  },
  breakpoints: [metrics.phone, metrics.tablet, metrics.desktop],
  font: 'work-sans',
  metrics,
  sizings,
  baseSize,
  baseFontSize,
  smallFontSize,
  baseLineHeight,
  baseFontWeight,
  sizeByFactor,
  textShadow: '1px 1px 3px #000',
  chartColorScheme,
  checklistsColorScheme,
  headingH1: {
    weight: 'normal',
  },
  headingH2: {
    size: 16,
    weight: 400,
  },
  colors: {
    chartsTarget: '#FF5D00', // orange
    chartsTargetTransparent: 'rgba(255, 93, 0, 0.5)',
    chartsOtherTarget: '#a93e00', // red
    chartsComparisonGroup: '#113678',
    chartsMyself: '#56A2DE',
    chartsIsOk: '#20BF55', // colour used for when something is ok.
    chartsIsLvl1Bad: '#FFBD00', // yellow
    chartsIsLvl2Bad: '#FF6400', // orange
    chartsIsLvl3Bad: '#BA0000', // red
    chartsTick: '#666666',
    actionBarItem: '#113678',
    actionsCta: '#FF5D00',
    actionsCtaTonedDown: '#fbb48b',
    actionsCtaTransparent: 'rgba(247, 93, 4, 0.8)',
    actionsCtaSecondary: '#D9550F', // deprecated, replaced by brandSupport
    brandSupport: '#D9550F', // colour is copy of actionsCtaSecondary
    brandSupportTonedDown: '#dae8f3',
    actionsCtaTertiery: '#FFEEE5',
    actionsStandard: '#56A2DE',
    mainNavActionButtonBackground: '#2A55A1',
    mainNavMenuHoverBackground: '#2A55A1',
    mainNavLine: '#2A55A1',
    actionBarBackgroundHover: '#113678',
    actionsStandardText: '#ffffff',
    actionsStandardSecondary: '#4083C0',
    brandAlfa: '#111857', // deprecated, do not use this colour variable anymore, instead use `text` or `darkBackground`
    darkestBackground: '#111857', // combine with `textOnDarkBackground` for texts.
    text: '#111857', // default text and icon colour, matches brandAlfa.
    textLighter: '#265C9F', // A lighter version of text
    textTonedDown: '#597897',
    textOnDarkBackground: '#ffffff',
    level2Heading: '#FF5D00',
    brandAlfaTransparent: 'rgba(38, 92, 159, 0.8)',
    brandBravo: '#012966', // deprecated, as background color use darkBackground instead.
    darkBackground: '#012966',
    brandCharlie: '#7186AC', // do not use for texts, poor legibility on top of white.
    brandDelta: '#DBE5E8',
    inputLabel: '#7186AC',
    isolatedBackground: '#E9F0F2', // Background for todo's, comments, placeholder images
    isolatedDarkerBackground: '#B8C4D5', // used to show toned down pictures in placeholder images
    brandFoxtrot: '#F4F7F8',
    brandGolf: '#ffffff', // deprecated, use sectionBackground instead.
    sectionBackground: '#ffffff', // used for a section with information content
    licensePlate: '#FFDA24',
    licensePlatePlaceholder: '#BAA132',
    stateNegative: '#E40000',
    statePositive: '#139018', // enough contrast on top of light backgrounds.
    transparent: 'transparent',
    fieldBackground: '#F4F7F8',
    errorText: '#F00D0D',
    errorBackground: '#FFEEE5',
    errorBorderActive: '#FF5D00', // Stronger than errorBorderInactive
    errorBorderInactive: '#FFEEE5',
    defaultBackground: '#fff', // like 'brandGolf'.
    alternativeBackground: '#F4F7F8', // used for alternate layouts (pop-outs, overlays)
    inactiveTextColor: '#a9b2c1',
    inactiveBackground: '#DBE5E8', // matches brandDelta.
    darkOnLightBorder: '#DBE5E8', // matches brandDelta.
    autoTaxatiePartners: '#E7508B',
    disabledInputBackground: '#f8f8f8',
    errorInputBackground: '#ffeeee',
    requiredInputBackground: '#fff7f2',
    inputPlaceholder: 'rgba(38, 92, 159, 0.9)',
    statusOk: '#20BF55',
    statusFinalized: '#057c3a',
    statusNeutral: '#56A2DE',
    statusWarningLvl1: '#F5BB03',
    statusWarningLvl2: '#E16D2A',
    statusErrorLvl1: '#DB0404',
    statusErrorLvl2: '#990000',
    statusIncomplete: '#FF5D00',
    statusComplete: '#3CB61D',
    statusIrrelevant: '#B9C5D5',
  },
  shadows: {
    bottom: '0 20px 30px -20px rgba(0, 0, 0, .15)',
    bottomSoft: '0 10px 20px -20px rgba(0,0,0,0.15)',
    bottomRight: '0 4px 10px 0 rgba(0,0,0,0.15)',
    modal: '0 0 25px 0 #111857aa',
    tooltip: '2px 2px 7px 1px #11185733',
    top: '0 -20px 20px -14px rgba(0,0,0,.075)',
  },
  gradients: {
    tabBottomShadow:
      'linear-gradient(0deg,rgba(245,245,245,1) 0%,rgba(255,255,255,1) 17%,rgba(255,255,255,1) 100%)',
    positiveToNegative: '', // Will be set below, it uses colors from the theme
  },
  heights,
  widths,
}

ucc.gradients.positiveToNegative = `linear-gradient(to right, ${ucc.colors.statePositive} 0%, ${ucc.colors.brandAlfa} 50%, ${ucc.colors.stateNegative} 65%)`

const autopresentatie = {
  ...ucc,
  productName: `themeOptions.${AppThemesInternationalization.AUTOPRESENTATIE}.productName`,
  login: {
    backgroundImage: '/images/cc-loginbg.jpg',
    payoff: `themeOptions.${AppThemesInternationalization.AUTOPRESENTATIE}.login.payoff`,
    payoffForceLowercase: false,
    showMadeThanksTo: true,
    madeThanksTo: `themeOptions.${AppThemesInternationalization.AUTOPRESENTATIE}.login.madeThanksTo`,
    showCopyrightNotice: false,
    copyrightNotice: `themeOptions.${AppThemesInternationalization.AUTOPRESENTATIE}.login.copyrightNotice`,
  },
  showNewsFeed: true,
  logo: '/images/cc-logo.svg',
  logoMark: '/images/cc-logo-mark.svg',
  faviconsFolder: '/images/favicons-cc',
  supportEmail: `themeOptions.${AppThemesInternationalization.AUTOPRESENTATIE}.supportEmail`,
  supportPhone: `themeOptions.${AppThemesInternationalization.AUTOPRESENTATIE}.supportPhone`,
  supportPhoneExtra: `themeOptions.${AppThemesInternationalization.AUTOPRESENTATIE}.supportPhoneExtra`,
  supportMenu: {
    showPortals: false,
    showSupportQuestion: true,
    showManual: true,
    manual: `themeOptions.${AppThemesInternationalization.AUTOPRESENTATIE}.supportMenu.manual`,
    showTeamviewer: true,
    teamviewer: `themeOptions.${AppThemesInternationalization.AUTOPRESENTATIE}.supportMenu.teamviewer`,
  },
  colors: {
    ...ucc.colors,
    actionBarItem: '#19273c',
    actionsCta: '#0087c8',
    brandSupport: '#0076af',
    brandAlfa: '#19273c',
    text: '#19273c',
    brandBravo: '#19273c',
    darkBackground: '#19273c',
    brandCharlie: '#606e86',
    inputLabel: '#19273c',
  },
}

const todrive = {
  ...ucc,
  font: '"Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", sans-serif;',
  productName: `themeOptions.${AppThemesInternationalization.TODRIVE}.productName`,
  login: {
    backgroundImage: '/images/2drive-loginbg.jpg',
    payoff: `themeOptions.${AppThemesInternationalization.TODRIVE}.login.payoff`,
    payoffForceLowercase: true,
    showMadeThanksTo: true,
    madeThanksTo: `themeOptions.${AppThemesInternationalization.TODRIVE}.login.madeThanksTo`,
    showCopyrightNotice: false,
    copyrightNotice: `themeOptions.${AppThemesInternationalization.TODRIVE}.login.copyrightNotice`,
  },
  showNewsFeed: true,
  logo: '/images/2drive-logo.svg',
  logoMark: '/images/2drive-logo-mark.svg',
  faviconsFolder: '/images/favicons-2drive',
  supportEmail: `themeOptions.${AppThemesInternationalization.TODRIVE}.supportEmail`,
  supportPhone: `themeOptions.${AppThemesInternationalization.TODRIVE}.supportPhone`,
  supportPhoneExtra: `themeOptions.${AppThemesInternationalization.TODRIVE}.supportPhoneExtra`,
  supportMenu: {
    showPortals: false,
    showSupportQuestion: true,
    showManual: false,
    showTeamviewer: true,
    teamviewer: `themeOptions.${AppThemesInternationalization.TODRIVE}.supportMenu.teamviewer`,
  },
  colors: {
    ...ucc.colors,
    actionBarItem: '#2675C1',
    actionsStandard: '#04537F',
    actionsStandardSecondary: '#00446B',
    actionsCta: '#FF5200',
    brandSupport: '#EE3800',
    brandAlfa: '#2675C1',
    text: '#2675C1',
    brandBravo: '#2675C1',
    darkBackground: '#2675C1',
    brandCharlie: '#2675C1',
    mainNavMenuHoverBackground: '#2675C1',
    inputLabel: '#2675C1',
    textTonedDown: '#2675C1',
  },
}

const widgets = {
  ...ucc,
  font: 'Open Sans',
  baseFontSize: '14px',
  colors: {
    ...ucc.colors,
    actionsStandard: '#297E9E',
    inputPlaceholder: '#909090',
    level1Heading: '#000000',
    level2Heading: '#444444',
    text: '#444444',
    chartIsOK: '#5BC552',
    defaultBackground: '#ffffff',
    sectionBackground: '#F4F4F4',
    alternativeBackground: '#ffffff',
    fieldBackground: '#ffffff',
  },
  headingH1: {
    weight: 'bold',
  },
  headingH2: {
    size: 18,
    weight: 'bold',
  },
}

export default {
  ucc,
  autopresentatie,
  todrive,
  widgets,
}
