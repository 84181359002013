import Counter from 'components/atoms/counter'
import Icon from 'components/atoms/icon'
import Link from 'components/atoms/link'
import Text from 'components/atoms/text'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'
import PopupMenu from './popup-menu'

const Container = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  position: relative;
  width: ${({ theme }) => theme.sizings.lvl6};
  height: ${({ theme }) => theme.sizings.lvl6};

  &::before {
    content: '';
    position: absolute;
    right: 100%;
    top: 0;
    height: 100%;
    width: ${({ theme }) => theme.sizings.lvl1};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.text};
    background-color: ${({ theme }) => theme.colors.actionBarItem};
  }
`

const FlyoutContainer = styled.div`
  bottom: 0;
  display: none;
  position: absolute;
  right: 100%;
  top: 0;

  ${Container}:hover & {
    ${({ showHoverLabel }) =>
      showHoverLabel &&
      `
      display: block;
    `}
  }
`

const Label = styled(Text)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.actionBarItem};
  border-bottom-left-radius: ${({ theme }) => theme.sizings.lvl0};
  border-top-left-radius: ${({ theme }) => theme.sizings.lvl0};
  display: flex;
  height: 100%;
  padding-left: 12px;
  padding-right: ${({ theme }) => theme.sizings.lvl0};
  white-space: nowrap;
`

const IconContainer = styled.div`
  justify-content: center;
  display: flex;
  position: relative;
`

const CaretIcon = styled(Icon)`
  position: absolute;
  left: 4px;
`
const StyledOffCaretIcon = styled(CaretIcon)`
  ${Container}:hover & {
    display: none;
  }
`
const StyledOnCaretIcon = styled(CaretIcon)`
  display: none;
  ${Container}:hover & {
    display: block;
  }
`

const StyledOffIcon = styled(Icon)`
  ${Container}:hover & {
    display: none;
  }
`

const StyledOnIcon = styled(Icon)`
  display: none;

  ${Container}:hover & {
    display: block;
  }
`

const StyledCounter = styled(Counter)`
  position: absolute;
  right: -12px;
  top: -10px;
`

function ActionBarItem({
  count,
  href,
  icon,
  iconDefaultColor,
  iconHoverColor,
  label,
  onClick,
  showHoverLabel,
  to,
  items,
  ...restProps
}) {
  const [showSubMenu, setShowSubMenu] = React.useState(false)

  const offIcon = (
    <StyledOffIcon type={icon} color={iconDefaultColor} height="24" />
  )
  const onIcon = <StyledOnIcon type={icon} color={iconHoverColor} height="24" />
  const counter = <StyledCounter count={count} data-test-e2e="counter" />

  return (
    <Container
      href={items && items.length ? null : href}
      onClick={onClick}
      onMouseEnter={
        items && items.length ? () => setShowSubMenu(true) : () => {}
      }
      onMouseLeave={
        items && items.length ? () => setShowSubMenu(false) : () => {}
      }
      to={to}
      {...restProps}
    >
      {items && items.length ? (
        <>
          <StyledOffCaretIcon
            type="caretLeft"
            height="6"
            width="4"
            color={iconDefaultColor}
          />
          <StyledOnCaretIcon
            type="caretLeft"
            height="6"
            width="4"
            color={iconHoverColor}
          />
          <PopupMenu
            items={items}
            positionMenu={{ toLeft: true }}
            isMenuActive={showSubMenu}
          >
            <IconContainer>
              {offIcon}
              {onIcon}
              {!!count && counter}
            </IconContainer>
          </PopupMenu>
        </>
      ) : (
        <>
          <IconContainer>
            {offIcon}
            {onIcon}
            {!!count && counter}
          </IconContainer>

          <FlyoutContainer showHoverLabel={showHoverLabel}>
            <Label text={label} color="brandGolf" />
          </FlyoutContainer>
        </>
      )}
    </Container>
  )
}

ActionBarItem.propTypes = {
  iconDefaultColor: PropTypes.string,
  iconHoverColor: PropTypes.string,
  count: PropTypes.number,
  href: PropTypes.string,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  showHoverLabel: PropTypes.bool,
  to: PropTypes.string,
  items: PropTypes.array,
}

ActionBarItem.defaultProps = {
  iconDefaultColor: 'brandBravo',
  iconHoverColor: 'brandGolf',
  count: null,
  href: null,
  onClick: null,
  showHoverLabel: true,
  to: undefined,
  items: [],
}

export default ActionBarItem
