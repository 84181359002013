import ActionBarItem from 'components/molecules/action-bar-item'
import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'
import styled from 'styled-components/macro'
import { formatDataTestE2eAttr } from 'utilities/format'

const Container = styled.div`
  background-color: ${({ backgroundColor, theme }) =>
    theme.colors[backgroundColor]};
  padding: 16px 0;
`

const ActionBarSection = forwardRef(
  ({ backgroundColor, iconDefaultColor, iconHoverColor, items }, ref) => {
    return (
      <Container backgroundColor={backgroundColor} ref={ref}>
        {items &&
          items.map(({ id, showHoverLabel, ...item }, index) => (
            <ActionBarItem
              key={index.toString()}
              iconDefaultColor={iconDefaultColor}
              iconHoverColor={iconHoverColor}
              showHoverLabel={showHoverLabel}
              data-test-e2e={formatDataTestE2eAttr('action-bar-item', id)}
              {...item}
            />
          ))}
      </Container>
    )
  },
)

ActionBarSection.propTypes = {
  backgroundColor: PropTypes.string,
  iconDefaultColor: PropTypes.string,
  iconHoverColor: PropTypes.string,
  items: PropTypes.array,
}

ActionBarSection.defaultProps = {
  backgroundColor: undefined,
  iconDefaultColor: undefined,
  iconHoverColor: undefined,
  items: null,
}

export default ActionBarSection
