/* eslint-disable import/no-cycle */
import { Button, Typography } from '@material-ui/core'
import Icon from 'components/atoms/icon'
import Link from 'components/atoms/link'
import Menu from 'components/organisms/menu'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components/macro'
import PopupMenu from './popup-menu'

function getHoverColor(color) {
  return (
    {
      brandBravo: 'mainNavMenuHoverBackground',
      brandGolf: 'brandFoxtrot',
      textOnDarkBackground: 'red',
    }[color] || 'brandFoxtrot'
  )
}

const StyledIcon = styled(Icon)`
  margin-right: 16px;
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  background-color: ${({ $backgroundColor, theme }) =>
    theme.colors[$backgroundColor]};
  display: flex;
  padding: ${({ $variant, theme }) =>
    $variant === 'popup' ? theme.sizings.lvl1 : '12px 32px'};

  &:hover {
    background-color: ${({ $backgroundColor, theme }) =>
      theme.colors[getHoverColor($backgroundColor)]};
  }

  &::first-letter {
    text-transform: capitalize;
  }

  > * {
    flex: 0 0 24px;
    display: block;
  }
`

const StyledButton = styled(Button)`
  text-align: left;
  text-transform: none;
  width: 100%;
  background-color: ${({ $backgroundColor, theme }) =>
    theme.colors[$backgroundColor]};
  padding: ${({ $variant, theme }) =>
    $variant === 'popup' ? theme.sizings.lvl1 : '12px 32px'};

  &:hover {
    background-color: ${({ $backgroundColor, theme }) =>
      theme.colors[getHoverColor($backgroundColor)]};
  }

  &::first-letter {
    text-transform: capitalize;
  }
`

const MenuItemText = styled(Typography)`
  flex: 1 1;
  color: ${({ $color, theme }) => theme.colors[$color] || 'inherit'};
  white-space: nowrap;

  &::first-letter {
    text-transform: capitalize;
  }
`

const ArrowIcon = styled(Icon)`
  margin-left: auto;
  color: ${({ $color }) => $color || 'inherit'};
  transform: ${({ rotate }) => (rotate ? 'rotateX(180deg)' : '')};
  svg {
    /* adopt the same color as used for text: */
    fill: currentColor;
  }
`

function MenuItem({
  // eslint-disable-next-line react/prop-types
  backgroundColor,
  closeMenu,
  color,
  icon,
  items,
  label,
  to,
  hasMobileLayout,
  hideMobile,
  variant,
  onClick,
  ...restProps
}) {
  const [submenuOpen, setSubmenuOpen] = useState(false)

  function toggleSubmenu() {
    setSubmenuOpen(!submenuOpen)
  }

  if (items) {
    if (hasMobileLayout) {
      return (
        <>
          <StyledButton
            color={color}
            $backgroundColor={backgroundColor}
            onClick={toggleSubmenu}
            {...restProps}
          >
            {icon && <StyledIcon color={color} type={icon} />}
            <MenuItemText $color={color}>{label}</MenuItemText>
            <ArrowIcon
              type="arrowDropDown"
              color={color}
              rotate={submenuOpen}
            />
          </StyledButton>
          {submenuOpen && <Menu closeMenu={closeMenu} items={items} />}
        </>
      )
    }
    return (
      <PopupMenu
        items={items}
        positionMenu={{ toLeft: true }}
        isMenuActive={submenuOpen}
      >
        <StyledButton
          color={color}
          level="option"
          $backgroundColor={backgroundColor}
          onClick={toggleSubmenu}
          $variant={variant}
          {...restProps}
        >
          {icon && <StyledIcon color={color} type={icon} />}
          <MenuItemText $color={color}>{label}</MenuItemText>

          <ArrowIcon type="arrowDropDown" color={color} rotate={submenuOpen} />
        </StyledButton>
      </PopupMenu>
    )
  }
  return (
    <Link onClick={closeMenu}>
      {onClick && !to ? (
        <StyledButton
          $backgroundColor={backgroundColor}
          color={color}
          level="option"
          onClick={onClick}
          {...restProps}
        >
          {icon && <StyledIcon color={color} type={icon} />}
          <MenuItemText $color={color}>{label}</MenuItemText>
        </StyledButton>
      ) : (
        <StyledLink
          $backgroundColor={backgroundColor}
          color={color}
          to={to}
          {...restProps}
        >
          {icon && <StyledIcon color={color} type={icon} />}
          <MenuItemText>{label}</MenuItemText>
        </StyledLink>
      )}
    </Link>
  )
}

MenuItem.propTypes = {
  backgroundColor: PropTypes.string,
  closeMenu: PropTypes.func,
  color: PropTypes.string,
  icon: PropTypes.string,
  items: PropTypes.array,
  label: PropTypes.string.isRequired,
  to: PropTypes.string,
  onClick: PropTypes.func,
  hideMobile: PropTypes.bool,
  hasMobileLayout: PropTypes.bool,
  variant: PropTypes.oneOf(['popup', 'main']),
}

MenuItem.defaultProps = {
  backgroundColor: 'brandGolf',
  closeMenu: () => {},
  color: 'brandAlfa',
  icon: null,
  items: null,
  to: null,
  onClick: null,
  hideMobile: false,
  hasMobileLayout: false,
  variant: 'main',
}

export default MenuItem
