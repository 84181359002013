import gql from 'graphql-tag'

export const checklistItemStates = gql`
  fragment checklistItemStates on FlexflowOverviewChecklistItemState {
    id
    checklistItemId
    leadTime
    state
  }
`
