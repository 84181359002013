import { useMutation } from '@apollo/client'
import ChecklistStatusBadge, {
  propTypes as checklistStatusBadgePropTypes,
} from 'components/atoms/checklist-status-badge'
import Link from 'components/atoms/link'
import VerticalTableColumnHeader from 'components/atoms/vertical-table-column-header'
import CarCard, {
  propTypes as carPropTypes,
} from 'components/molecules/car-card'
import Typography from 'components/molecules/typography'
import ScrollableTable, {
  propTypes as scrollableTablePropTypes,
} from 'components/organisms/scrollable-table'
import { CarChecklistItemStatusses } from 'config/enums'
import { CHECKLIST_UPDATE_CHECKLIST_ITEM_STATUS } from 'config/graphql/v4'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import styled from 'styled-components/macro'
import toast from 'utilities/toast'

const StyledScrollableTable = styled(ScrollableTable)`
  .table {
    border-collapse: separate; // Else the cell borders are not sticky
  }

  .cell {
    vertical-align: middle;
    padding: ${({ theme }) => theme.sizings.lvl1}
      ${({ theme }) => theme.sizings.lvl2};
    text-align: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.darkOnLightBorder};
    transition: background-color 0.2s linear;
  }

  .headCell {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.darkOnLightBorder};
    border-right: 1px solid ${({ theme }) => theme.colors.darkOnLightBorder};
    transition: background-color 0.2s linear;

    &:last-child {
      border-right: 0;
    }
  }

  .firstHeadCell {
    padding: ${({ theme }) => theme.sizings.lvl1};
    padding-left: 0;
    text-align: left;
  }

  .firstCell,
  .firstHeadCell {
    padding-left: 0;
    border-right: 1px solid ${({ theme }) => theme.colors.darkOnLightBorder};
  }

  .firstCell {
    text-align: left;
  }
`

const HeadLink = styled(Link)`
  width: 100%;
  display: block;
  position: relative;
  padding: ${({ theme }) => theme.sizings.lvl1};

  p {
    transition: color 0.2s linear;

    ${({ $isSelected, $isHighlighted, theme }) =>
      $isSelected || $isHighlighted
        ? `color: ${$isSelected ? theme.colors.actionsCta : theme.colors.actionsStandard};`
        : ''}
    ${({ $isSelected }) => ($isSelected ? 'font-weight: 600;' : '')}
  }

  &:hover p {
    color: ${({ theme }) => theme.colors.actionsStandard};
  }
`

const TopLeftCellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: ${({ theme }) => theme.sizings.lvl0};
`

const Title = styled(Typography)`
  margin-top: 0;
  margin-bottom: 0;
  text-transform: uppercase;
`

const Subtitle = styled(Typography)`
  margin-top: 0;
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.brandCharlie};
`

const CarCardLink = styled(Link)`
  display: block;
`

const SingleChecklistStatusTable = ({
  title,
  subtitle,
  checklistItems,
  rows,
  onHeadItemClick,
  ...restProps
}) => {
  const highlightBgColor = 'isolatedBackground'
  const highlightedBgIndexes = React.useMemo(() => {
    const indexes = []
    checklistItems.forEach((item, i) => {
      if (item.isHighlighted) {
        indexes.push(i)
      }
    })
    return indexes
  }, [checklistItems])
  const [carIdBeingUpdated, setCarIdBeingUpdated] = React.useState(null)

  const [updateChecklistItemStatusMutation] = useMutation(
    CHECKLIST_UPDATE_CHECKLIST_ITEM_STATUS,
  )
  const updateChecklistItemStatus = useCallback(
    async (carId, checkListItemState) => {
      setCarIdBeingUpdated(carId)
      const isChecked =
        checkListItemState.state === CarChecklistItemStatusses.completed ||
        checkListItemState.state ===
          CarChecklistItemStatusses.completedAfterDelay
      updateChecklistItemStatusMutation({
        variables: {
          checklistItemId: checkListItemState.checklistItemId,
          carFileId: carId,
          // if it has a status, it will be toggled back to a not started status,
          // and if it is not started, it will be toggled to a started status
          checked: !isChecked,
        },
      })
        .then(() => {
          toast.success('The status has been updated.')
        })
        .catch((error) => {
          console.error(error)
          toast.error(
            'The status could not be updated. Please contact UCC if this problem persists.',
          )
        })
        .finally(() => {
          setCarIdBeingUpdated(null)
        })
    },
    [updateChecklistItemStatusMutation],
  )
  const formattedColumns = React.useMemo(
    () => [
      {
        id: 'checklist-info',
        width: '350px',
        content:
          title || subtitle ? (
            <TopLeftCellWrapper>
              {title && <Title type="Level4Heading">{title}</Title>}
              {subtitle && <Subtitle type="BodyParagraph">{subtitle}</Subtitle>}
            </TopLeftCellWrapper>
          ) : undefined,
      },
      ...checklistItems.map((item, i) => ({
        id: item.id,
        content: (
          <HeadLink
            className="headLink"
            onClick={(e) => {
              if (typeof onHeadItemClick === 'function') {
                onHeadItemClick(item)
              }
            }}
            $isHighlighted={item.isHighlighted}
            $isSelected={item.isSelected}
          >
            <VerticalTableColumnHeader>{item.name}</VerticalTableColumnHeader>
          </HeadLink>
        ),
        bgColor: highlightedBgIndexes.includes(i)
          ? highlightBgColor
          : undefined,
      })),
    ],
    [checklistItems, onHeadItemClick, title, subtitle, highlightedBgIndexes],
  )

  const formattedRows = React.useMemo(
    () =>
      rows.map((row) => [
        row.car.link ? (
          <CarCardLink key="car-link" to={row.car.link}>
            <CarCard {...row.car} />
          </CarCardLink>
        ) : (
          <CarCard key="car" {...row.car} />
        ),
        ...row.checklistStatusses.map((status, i) => ({
          content: (
            <ChecklistStatusBadge
              key={`${row.id}_${i}`}
              {...status}
              addSpacingForUnderline
              onClick={() => {
                updateChecklistItemStatus(row.car.id, status)
              }}
              disabled={carIdBeingUpdated === row.car.id}
              data-label={status.state} // so we read the state of a badge.
            />
          ),
          bgColor: highlightedBgIndexes.includes(i)
            ? highlightBgColor
            : undefined,
        })),
      ]),
    [rows, carIdBeingUpdated, highlightedBgIndexes, updateChecklistItemStatus],
  )

  return (
    <StyledScrollableTable
      columns={formattedColumns}
      rows={formattedRows}
      {...restProps}
    />
  )
}

const { rows, columns, ...restScrollableTablePropTypes } =
  scrollableTablePropTypes

SingleChecklistStatusTable.propTypes = {
  title: PropTypes.string, // Will be shown on the top left
  subtitle: PropTypes.string, // Will be shown on the top left
  // The order of this array matters. Items get rendered in this order. Make sure this is the same as the `rows.checklistStatusses` prop.
  checklistItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      isHighlighted: PropTypes.bool, // Colors the head text blue
      isSelected: PropTypes.bool, // Colors the head text orange, and makes it bold
    }),
  ).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      car: PropTypes.shape({
        id: PropTypes.string.isRequired,
        link: PropTypes.string, // An internal link.
        ...carPropTypes,
      }).isRequired,
      // The order of this array matters. Items get rendered in this order. Make sure this is the same as the `checklistItems` prop.
      checklistStatusses: PropTypes.arrayOf(
        PropTypes.shape({ ...checklistStatusBadgePropTypes }).isRequired,
      ).isRequired,
    }),
  ).isRequired,
  onHeadItemClick: PropTypes.func, // Can be used for sorting
  ...restScrollableTablePropTypes,
}

export default SingleChecklistStatusTable
