import { gql } from '@apollo/client'

import { CarFileStatusEnumUpperCase } from 'config/enums'

import { carChecklist } from '../fragments/car'
import checklistInfo from '../fragments/checklist'
import {
  checklistItemAdvancedInfo,
  checklistItemBasicInfo,
} from '../fragments/checklist-item'
import dashboardChecklistInfo from '../fragments/dashboard-checklist'
import { checklistItemStates } from '../fragments/dashboard-checklist-states'
import dashboardChecklistStatusInfo from '../fragments/dashboard-checklist-status'
import progressInfo from '../fragments/progress'
import widgetByStatusResultMeta from '../fragments/widget-by-status-result'

// NEW STUFF FOR 2023
export const CHECKLISTS_WIDGET_STATUSSES = gql`
  query flexFlowWidgetByStatus($locationId: ID) {
    ${CarFileStatusEnumUpperCase.lopend}: flexFlowWidgetByStatus(status: LOPEND, dealerId: $locationId) {
      delayed
    }

    ${CarFileStatusEnumUpperCase.verwacht}: flexFlowWidgetByStatus(status: VERWACHT, dealerId: $locationId) {
      delayed
    }

    ${CarFileStatusEnumUpperCase.binnen}: flexFlowWidgetByStatus(status: BINNEN, dealerId: $locationId) {
      delayed
    }

    ${CarFileStatusEnumUpperCase.verkocht}: flexFlowWidgetByStatus(status: VERKOCHT, dealerId: $locationId) {
      delayed
    }

    ${CarFileStatusEnumUpperCase.afgeleverd}: flexFlowWidgetByStatus(status: AFGELEVERD, dealerId: $locationId) {
      delayed
    }
  }
`

export const CHECKLISTS_FOR_STATUS = gql`
  query checklistsForStatus($status: CarFileStatusEnum!, $locationId: ID) {
    flexFlowWidgetByStatus(status: $status, dealerId: $locationId) {
      ...widgetByStatusResultMeta
      checklist {
        ...checklistInfo
      }
    }
  }
  ${widgetByStatusResultMeta}
  ${checklistInfo}
`

export const CHECKLIST_WIDGET_DETAILS = gql`
  query checklistsWidgetDetails($checklistId: ID!) {
    flexFlowWidgetByChecklist(checklistId: $checklistId) {
      checklistItem {
        ...checklistItemBasicInfo
        checklist {
          id
        }
      }
      total
      delayed
      delayedPercentage
      averageDuration
    }
  }
  ${checklistItemBasicInfo}
`

export const SINGLE_CHECKLIST_OVERLAY_DETAILS = gql`
  query singleChecklistOverlayDetails(
    $checklistId: ID!
    $checklistItemId: ID
    $sortOnChecklistItemId: ID
    $first: Int!
    $page: Int
    $sortDirection: SortDirection
    $filterState: FlexflowOverviewFilter
  ) {
    flexFlowWidgetOverview(
      checklistId: $checklistId
      checklistItemId: $checklistItemId
      sortOnChecklistItemId: $sortOnChecklistItemId
      first: $first
      page: $page
      sortDirection: $sortDirection
      filterState: $filterState
    ) {
      checklist {
        ...checklistInfo
        items {
          id
          name
        }
      }
      cars {
        car {
          ...carChecklist
        }
        checklistItemStates {
          ...checklistItemStates
        }
      }
      paginatorInfo {
        total
        currentPage
        perPage
      }
    }
  }
  ${checklistInfo}
  ${carChecklist}
  ${checklistItemStates}
`
// END OF NEW STUFF

// OLD, not used anymore
export const DASHBOARD_SUMMARY = gql`
  query dashboardSummary($locationId: ID) {
    lopend: dashboardSummary(status: LOPEND, vestigingsId: $locationId) {
      ...dashboardChecklistStatusInfo
      checklists {
        ...dashboardChecklistInfo
      }
    }

    verwacht: dashboardSummary(status: VERWACHT, vestigingsId: $locationId) {
      ...dashboardChecklistStatusInfo
      checklists {
        ...dashboardChecklistInfo
      }
    }

    binnen: dashboardSummary(status: BINNEN, vestigingsId: $locationId) {
      ...dashboardChecklistStatusInfo
      checklists {
        ...dashboardChecklistInfo
      }
    }

    verkocht: dashboardSummary(status: VERKOCHT, vestigingsId: $locationId) {
      ...dashboardChecklistStatusInfo
      checklists {
        ...dashboardChecklistInfo
      }
    }

    afgeleverd: dashboardSummary(
      status: AFGELEVERD
      vestigingsId: $locationId
    ) {
      ...dashboardChecklistStatusInfo
      checklists {
        ...dashboardChecklistInfo
      }
    }
  }
  ${dashboardChecklistStatusInfo}
  ${dashboardChecklistInfo}
`
// /OLD

export const CHECKLISTS_COUNT = gql`
  query checklistCounts($locationId: ID) {
    lopend: dashboardSummary(status: LOPEND, vestigingsId: $locationId) {
      uniqueCarCount
    }

    verwacht: dashboardSummary(status: VERWACHT, vestigingsId: $locationId) {
      uniqueCarCount
    }

    binnen: dashboardSummary(status: BINNEN, vestigingsId: $locationId) {
      uniqueCarCount
    }

    verkocht: dashboardSummary(status: VERKOCHT, vestigingsId: $locationId) {
      uniqueCarCount
    }

    afgeleverd: dashboardSummary(
      status: AFGELEVERD
      vestigingsId: $locationId
    ) {
      uniqueCarCount
    }
  }
`

export const CHECKLIST_STATUSSES_FOR_OVERLAY = gql`
  query checklistStatussesForOverlay($locationId: ID) {
    lopend: dashboardSummary(status: LOPEND, vestigingsId: $locationId) {
      ...dashboardChecklistStatusInfo
    }

    verwacht: dashboardSummary(status: VERWACHT, vestigingsId: $locationId) {
      ...dashboardChecklistStatusInfo
    }

    binnen: dashboardSummary(status: BINNEN, vestigingsId: $locationId) {
      ...dashboardChecklistStatusInfo
    }

    verkocht: dashboardSummary(status: VERKOCHT, vestigingsId: $locationId) {
      ...dashboardChecklistStatusInfo
    }

    afgeleverd: dashboardSummary(
      status: AFGELEVERD
      vestigingsId: $locationId
    ) {
      ...dashboardChecklistStatusInfo
    }
  }
  ${dashboardChecklistStatusInfo}
`

// OLD, not used anymore
export const _CHECKLISTS_FOR_STATUS = gql`
  query checklistsForStatus($status: CarFileStatusEnum!, $locationId: ID) {
    dashboardSummary(status: $status, vestigingsId: $locationId) {
      checklists {
        id
        name
      }
    }
  }
`

export const CHECKLIST_DETAILS = gql`
  query checklistsForStatus($checklistId: ID!, $skip: Int, $take: Int) {
    dashboardChecklistSummary(
      checklistId: $checklistId
      skip: $skip
      take: $take
    ) {
      id
      name
      averageDays
      uniqueCarCount
      soort {
        soort
        checklist {
          items {
            id
            name
          }
        }
      }
    }
  }
`
// /OLD

export const CHECKLIST_CARS = gql`
  query checklistsForStatus(
    $checklistId: ID!
    $skip: Int
    $take: Int
    $checklistItemIdExpired: Int
    $hasExpired: Boolean # if not sent both expired and non-expired items will be retrieved
  ) {
    dashboardChecklistSummary(
      checklistId: $checklistId
      skip: $skip
      take: $take
      checklistItemIdExpired: $checklistItemIdExpired
      hasExpired: $hasExpired
    ) {
      id
      uniqueCarCount
      averageDays
      cars {
        auto {
          id
          kenteken
          merk
          modelserie
          type
          km_binnen
          km_tax
          jaardeel1
          firstCarimage {
            mediaUri
          }
        }
        progress {
          ...progressInfo
        }
      }
    }
  }
  ${progressInfo}
`

export const CAR_FILE_CHECKLIST_SUMMARY = gql`
  query carFileChecklists($carFileId: ID!) {
    carFileChecklistSummary(carFileId: $carFileId) {
      carFileId
      statuses {
        status
        totalProgress {
          ...progressInfo
        }
        totalBlockedProgress {
          ...progressInfo
        }
        startedAt
        finishedAt
        checklists {
          id
          name
          totalProgress {
            ...progressInfo
          }
          totalBlockedProgress {
            ...progressInfo
          }
          types
          checklistItems {
            id
            name
            description
            checked
            checkedBy {
              id
              voornaam
              achternaam
            }
            startOnChecklistItem {
              id
              name
              checkedAt
            }
            checkedAt
            maxDuration
          }
        }
      }
    }
  }
  ${progressInfo}
`

export const CAR_FILE_CHECKLIST_UPDATE_CHECKED_ITEMS = gql`
  mutation carFileChecklistUpdateCheckedItems(
    $carFileId: ID!
    $checklistItemIds: [ID!]!
  ) {
    carFileUpdateCheckedItems(
      carFileId: $carFileId
      checklistItemIds: $checklistItemIds
    ) {
      id
      checked
    }
  }
`
/**
 * Update a single item in a car file checklist:
 */
export const CHECKLIST_UPDATE_CHECKLIST_ITEM_STATUS = gql`
  mutation carFileUpdateCheckedItem(
    $carFileId: ID!
    $checklistItemId: ID!
    $checked: Boolean!
  ) {
    carFileUpdateCheckedItem(
      carFileId: $carFileId
      checklistItemId: $checklistItemId
      checked: $checked
    ) {
      car {
        ...carChecklist
      }
      checklistItemStates {
        ...checklistItemStates
      }
    }
  }
  ${carChecklist}
  ${checklistItemStates}
`

export const CAR_FILE_CHECKLIST_PROGRESS = gql`
  query carFileChecklists($carFileId: ID!) {
    carFileChecklistSummary(carFileId: $carFileId) {
      carFileId
      totalProgress {
        ...progressInfo
      }
      statuses {
        status
        totalProgress {
          ...progressInfo
        }
        totalBlockedProgress {
          ...progressInfo
        }
      }
    }
  }
  ${progressInfo}
`

// Settings stuff
export const CHECKLISTS_FOR_DEALER = gql`
  query checklists($locationId: ID!, $status: CarFileStatusEnum) {
    checklists(vestigingId: $locationId, status: $status) {
      id
      name
      soort {
        id
        soort
      }
      items {
        ...checklistItemBasicInfo
      }
      parentId # if !null means parent is a DealerCluster
    }
  }
  ${checklistItemBasicInfo}
`

export const SET_CHECKLIST_ITEM_ORDER = gql`
  mutation setChecklistItemOrder($checklistId: ID!, $checklistItemIds: [ID!]!) {
    setChecklistItemOrder(
      checklistId: $checklistId
      checklistItemIds: $checklistItemIds
    ) {
      id
      items {
        id
        order
      }
    }
  }
`

export const CHECKLIST = gql`
  query checklist($id: ID!) {
    checklist(id: $id) {
      ...checklistInfo
      items {
        ...checklistItemBasicInfo
      }
    }
  }
  ${checklistInfo}
  ${checklistItemBasicInfo}
`

export const CHECKLIST_BASICS = gql`
  query checklist($id: ID!) {
    checklist(id: $id) {
      ...checklistInfo
      items {
        id
        name
      }
    }
  }
  ${checklistInfo}
`

export const CHECKLIST_WITH_CLUSTERS = gql`
  query checklist($id: ID!) {
    checklist(id: $id) {
      ...checklistInfo
      items {
        ...checklistItemBasicInfo
      }
      clusters {
        id
        name
      }
    }
  }
  ${checklistInfo}
  ${checklistItemBasicInfo}
`

export const CHECKLIST_WITH_ITEM_DETAILS = gql`
  query checklist($id: ID!) {
    checklist(id: $id) {
      ...checklistInfo
      items {
        ...checklistItemBasicInfo
        ...checklistItemAdvancedInfo
      }
    }
  }
  ${checklistInfo}
  ${checklistItemBasicInfo}
  ${checklistItemAdvancedInfo}
`

export const CREATE_CHECKLIST = gql`
  mutation createChecklist(
    $locationId: ID
    $clusterIds: ChecklistClusterBelongsToMany
    $name: String!
    $status: CarFileStatusEnum!
    $types: [CarFileSoortEnum!]!
  ) {
    createChecklist(
      vestigingId: $locationId
      clusterIds: $clusterIds
      name: $name
      status: $status
      types: $types
    ) {
      ...checklistInfo
    }
  }
  ${checklistInfo}
`

export const UPDATE_CHECKLIST = gql`
  mutation updateChecklist(
    $id: ID!
    $clusterIds: ChecklistClusterBelongsToMany
    $name: String!
    $types: [CarFileSoortEnum!]!
  ) {
    updateChecklist(
      id: $id
      clusterIds: $clusterIds
      name: $name
      types: $types
    ) {
      ...checklistInfo
    }
  }
  ${checklistInfo}
`

export const DELETE_CHECKLIST = gql`
  mutation deleteChecklist($id: ID!) {
    deleteChecklist(id: $id) {
      id
      name
    }
  }
`

export const CREATE_CHECKLIST_ITEM = gql`
  mutation createChecklistItem(
    $checklistId: ID!
    $name: String!
    $description: String
    $notificationUserIds: ChecklistItemGebruikerBelongsToMany
    $blocking: Boolean
    $maxDuration: Int
    $alertMessage: String
    $triggerChecklistItemId: ID
    $reminderAfterDays: Int
    $reminderUserIds: ChecklistItemGebruikerBelongsToMany
    $automationTriggerIds: ChecklistItemAutomationsBelongsToMany
  ) {
    createChecklistItem(
      checklistId: $checklistId
      name: $name
      description: $description
      notificationUserIds: $notificationUserIds
      blocking: $blocking
      maxDuration: $maxDuration
      alertMessage: $alertMessage
      triggerChecklistItemId: $triggerChecklistItemId
      reminderAfterDays: $reminderAfterDays
      reminderUserIds: $reminderUserIds
      automationTriggerIds: $automationTriggerIds
    ) {
      ...checklistItemBasicInfo
      ...checklistItemAdvancedInfo
    }
  }
  ${checklistItemBasicInfo}
  ${checklistItemAdvancedInfo}
`

export const UPDATE_CHECKLIST_ITEM = gql`
  mutation updateChecklistItem(
    $id: ID!
    $name: String!
    $description: String
    $notificationUserIds: ChecklistItemGebruikerBelongsToMany
    $blocking: Boolean
    $maxDuration: Int
    $alertMessage: String
    $triggerChecklistItemId: ID
    $reminderAfterDays: Int
    $reminderUserIds: ChecklistItemGebruikerBelongsToMany
    $automationTriggerIds: ChecklistItemAutomationsBelongsToMany
  ) {
    updateChecklistItem(
      id: $id
      name: $name
      description: $description
      notificationUserIds: $notificationUserIds
      blocking: $blocking
      maxDuration: $maxDuration
      alertMessage: $alertMessage
      triggerChecklistItemId: $triggerChecklistItemId
      reminderAfterDays: $reminderAfterDays
      reminderUserIds: $reminderUserIds
      automationTriggerIds: $automationTriggerIds
    ) {
      ...checklistItemBasicInfo
      ...checklistItemAdvancedInfo
    }
  }
  ${checklistItemBasicInfo}
  ${checklistItemAdvancedInfo}
`

export const DELETE_CHECKLIST_ITEM = gql`
  mutation deleteChecklistItem($id: ID!) {
    deleteChecklistItem(id: $id) {
      id
      name
      checklist {
        id
        name
      }
    }
  }
`

export const USERS = gql`
  query users($locationId: ID!) {
    users(vestigingId: $locationId) {
      id
      voornaam
      achternaam
      email
    }
  }
`

export const AUTOMATIONS = gql`
  query automations {
    automations {
      id
      key
      label
    }
  }
`

export const CHECKLIST_NOTIFICATIONS = gql`
  query notifications($locationId: ID) {
    notifications(vestigingsId: $locationId) {
      id
      amount
      type
      target
      title
      data {
        key
        value
      }
    }
  }
`
